import React, { MouseEvent, useEffect } from "react";

// project-import
import IconButton from "../../components/@extended/IconButton";

import { Stack, Typography, RadioGroup, FormControlLabel, Radio, OutlinedInput, Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import { useMemo, useState } from "react";

import { CompanyType, UserType } from "../../AllTypes";
import axiosServices from "../../utils/axios";
import { CompanyEndpoints } from "../../AllLables";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { Chip } from "@mui/material";
import { alertMessage } from "../pages-helpers/AlertMessage";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider, FieldArray, ErrorMessage } from "formik";
import { AddCircle } from "@mui/icons-material";
import { DeleteOutlined } from "@ant-design/icons";

import { insert, updateUsingPut } from "../../api/generalRoute";
import ScrollX from "../../components/ScrollX";
import "./CompanyEdit.css";

import useTranslation from "../../hooks/useTranslation";
import { userLevelIds } from "utils/userPermissions";
import { useNavigate } from "react-router-dom";

type CompanyEditFormParams = {
  showHeader?: boolean;
  companyId?: string;
  onCancel: () => void;
  onSave: (id?: string) => void;
  // saveAndNext?: boolean;
};

export default function CompanyEditForm({
  showHeader = true,
  companyId,
  onCancel = () => {},
  onSave = () => {},
}: // saveAndNext = true,
CompanyEditFormParams) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let endpoints = CompanyEndpoints;

  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyType>();
  const [salesPersons, setSalesPersons] = useState<UserType[]>([]);

  const [refreshData, setRefreshData] = useState<boolean>(false);

  const isEditing = useMemo(() => companyId && companyId !== "0", [companyId]);

  function setDataFunction() {
    if (isEditing) {
      // TODO: refactor
      axiosServices
        .get(`/api/Company/getCompanyInfo?id=${companyId}`)
        .then((res) => {
          let values = res.data;
          values.salesPersons = values.salesPersons.map((x: any) => x.salesPersonId);

          // TODO: refactor
          setCompany(values);
          formik.setValues(values);
          // setSelectedItem(res.data.items[0]);
        })
        .catch((err) => {
          alertMessage("Something went wrong ", "error");
        });

      axiosServices
        .get(`/api/account?userLevel=${userLevelIds.SalesPerson}`)
        .then((res) => {
          setSalesPersons(res.data);
        })
        .catch((err) => {
          alertMessage("Something went wrong", "error", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  // maybe change to useEffet(() => {}, [companyId])
  const toggleRefreshData = () => {
    setRefreshData(!refreshData);
  };

  useEffect(() => {
    setDataFunction();
  }, [refreshData, isEditing]);

  const companyInitialValues = {
    name: "",
    shortName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    address: "",
    address2: "",
    isActive: true,
    isShippingAddressSameAsBillingAddress: true,
    locations: [],
    salesPersons: [] as string[],
    featureFlags: [],
    contactName: "",
    purchasingAccountsPayableName: "",
    purchasingAccountsPayableEmail: "",
    shippingAddress: "",
    shippingCity: "",
    shippingState: "",
    shippingCountry: "",
    shippingZip: "",
  };

  var phoneFormat = /^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$|^\([0-9]{3}\)-?[0-9]{3}-?[0-9]{4}$/;
  var phone10Digits = /^(?:\D*\d){10}\D*$/;

  // a regex to accept 10 digits (with any other characters)

  const locationSchema = Yup.object().shape({
    phone: Yup.string()
      .matches(phone10Digits, t("company.phoneFormat", "Phone no. must be of exactly 10 digits"))
      .matches(phoneFormat, t("company.phoneFormatMessage", "Use only numbers or format (123)-456-7890")),
  });

  const ItemSchema = Yup.object().shape({
    name: Yup.string().max(255).required(t("company.nameRequired", "Name is required")),
    email: Yup.string()
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i, t("company.invalidEmail", "Email Invalid"))
      .required(t("company.emailRequired", "Email is required"))
      .max(40),
    purchasingAccountsPayableEmail: Yup.string()
      .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i, t("company.invalidEmail", "Email Invalid"))
      .max(40)
      .nullable(),
    phone: Yup.string()
      .matches(phone10Digits, t("company.phoneFormat", "Phone no. must be of exactly 10 digits"))
      .matches(phoneFormat, t("company.phoneFormatMessage", "Use only numbers or format (123)-456-7890")),
    city: Yup.string().required(t("company.cityRequired", "City is required")),
    state: Yup.string().required(t("company.stateRequired", "State is required")),
    country: Yup.string().required(t("company.countryRequired", "Country is required")),
    zip: Yup.string().required(t("company.zipRequired", "Zip Code is required")),
    address: Yup.string().required(t("company.addressRequired", "Address is required")),
    locations: Yup.array().of(locationSchema),
  });

  const formik = useFormik({
    initialValues: companyInitialValues,
    validationSchema: ItemSchema,
    enableReinitialize: false,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // setSubmitting(false); return;
        let company = {
          ...values,
          salesPersons: Array.from(new Set(values.salesPersons)).map((id) => ({ salesPersonId: id })),
        };

        company.isActive = Boolean(company.isActive);

        // if (values.isActive === "true") company.isActive = true;
        // if (values.isActive === "false") company.isActive = false;

        console.log({ values });

        if (company.isShippingAddressSameAsBillingAddress) {
          company.shippingAddress = company.address;
          company.shippingCity = company.city;
          company.shippingState = company.state;
          company.shippingCountry = company.country;
          company.shippingZip = company.zip;
        }

        if (isEditing) {
          updateUsingPut(endpoints, companyId, company)
            .then((res) => {
              alertMessage(`Company updated successfully`, "success");
              onSave(companyId);
              // navigate("/admin/company");
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
              setSubmitting(false);
            });
        } else {
          insert(endpoints, company)
            .then((res) => {
              alertMessage(`Company added successfully`, "success");
              setSubmitting(false);
              onSave(`${res?.data?.id}`);
              navigate(`/admin/company/${res.data.id}`);
              toggleRefreshData();
            })
            .catch((err) => {
              alertMessage("Something went wrong", "error", err);
              setSubmitting(false);
            });
        }
      } catch (error) {
        alertMessage(`Something went wrong`, "error", error);
        setSubmitting(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <Box>
        <FormikProvider value={formik}>
          {/* {({ values }) => ( */}

          <Form noValidate onSubmit={handleSubmit}>
            {showHeader && (
              <>
                <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="flex-start">
                  <Typography variant="h2">{isEditing ? t("company.editTitle", "Edit Company") : t("company.addTitle", "Add Company")}</Typography>

                  <Stack spacing={1} direction="row" justifyContent="flex-end" sx={{ pb: 2 }}>
                    <Button color="secondary" onClick={() => onCancel()}>
                      {t("general.cancel", "Cancel")}
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                      {t("general.save", "Save")}
                    </Button>
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item xs={12} sm={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                      <Stack direction="row" alignItems="center">
                        {/* <InputLabel>Status :</InputLabel> */}
                        <RadioGroup {...getFieldProps("isActive")} aria-label="type" name="isActive" row>
                          <Box sx={{ fontSize: "20px", ml: 2 }}>
                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                          </Box>
                        </RadioGroup>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="name">Company Name</InputLabel>
                      <TextField
                        fullWidth
                        id="name"
                        placeholder="Company Name"
                        {...getFieldProps("name")}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="shortName">Short Name</InputLabel>
                      <TextField
                        fullWidth
                        id="shortName"
                        placeholder="Short Name"
                        {...getFieldProps("shortName")}
                        error={Boolean(touched.shortName && errors.shortName)}
                        helperText={touched.shortName && errors.shortName}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="phone">Phone Number</InputLabel>
                      <TextField
                        fullWidth
                        id="phone"
                        placeholder="Phone Number"
                        {...getFieldProps("phone")}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="contactName">Contact Name</InputLabel>
                      <TextField
                        fullWidth
                        id="contactName"
                        placeholder="Contact Name"
                        {...getFieldProps("contactName")}
                        error={Boolean(touched.contactName && errors.contactName)}
                        helperText={touched.contactName && errors.contactName}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="email">Contact Email Address</InputLabel>
                      <TextField
                        fullWidth
                        id="email"
                        type="email"
                        placeholder="Contact Email Address"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="purchasingAccountsPayableName">Purchasing Accounts Payable Name</InputLabel>
                      <TextField
                        fullWidth
                        id="shortName"
                        placeholder="Purchasing Accounts Payable Name"
                        {...getFieldProps("purchasingAccountsPayableName")}
                        error={Boolean(touched.purchasingAccountsPayableName && errors.purchasingAccountsPayableName)}
                        helperText={touched.purchasingAccountsPayableName && errors.purchasingAccountsPayableName}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="purchasingAccountsPayableEmail">Purchasing Accounts Payable Email</InputLabel>
                      <TextField
                        fullWidth
                        id="purchasingAccountsPayableEmail"
                        placeholder="Purchasing Accounts Payable Email"
                        {...getFieldProps("purchasingAccountsPayableEmail")}
                        error={Boolean(touched.purchasingAccountsPayableEmail && errors.purchasingAccountsPayableEmail)}
                        helperText={touched.purchasingAccountsPayableEmail && errors.purchasingAccountsPayableEmail}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
                      <InputLabel>{t("company.isShippingAddressSameAsBillingAddress", "Is Shipping Address same As Billing Address?")}</InputLabel>
                      <RadioGroup
                        row
                        {...getFieldProps("isShippingAddressSameAsBillingAddress")}
                        value={values.isShippingAddressSameAsBillingAddress}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue("isShippingAddressSameAsBillingAddress", e.target.value === "true");
                        }}
                      >
                        <FormControlLabel value={true} control={<Radio size="small" />} label={t("general.yes", "Yes")} />
                        <FormControlLabel value={false} control={<Radio size="small" />} label={t("general.no", "No")} />
                      </RadioGroup>
                    </Stack>
                  </Grid>

                  <Grid item container spacing={1} xs={12} sm={6}>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <Typography variant="h4" sx={{ py: 1 }}>
                          Billing Address
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="address">Address</InputLabel>
                        <TextField
                          fullWidth
                          id="address"
                          multiline
                          rows={2}
                          placeholder="Address"
                          {...getFieldProps("address")}
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                        />
                      </Stack>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="address2">Address Line 2</InputLabel>
                          <TextField
                            fullWidth
                            id="address2"
                            multiline
                            rows={2}
                            placeholder="Address (Optional)"
                            {...getFieldProps("address2")}
                            error={Boolean(touched.address2 && errors.address2)}
                            helperText={touched.address2 && errors.address2}
                          />
                        </Stack>
                      </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="city">City</InputLabel>
                        <TextField
                          fullWidth
                          id="city"
                          placeholder="City"
                          {...getFieldProps("city")}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="state">State</InputLabel>
                        <TextField
                          fullWidth
                          id="state"
                          placeholder="State"
                          {...getFieldProps("state")}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="country">Country</InputLabel>
                        <TextField
                          fullWidth
                          id="country"
                          placeholder="Country"
                          {...getFieldProps("country")}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="zip">Zip Code</InputLabel>
                        <TextField
                          fullWidth
                          id="zip"
                          placeholder="Zip Code"
                          {...getFieldProps("zip")}
                          error={Boolean(touched.zip && errors.zip)}
                          helperText={touched.zip && errors.zip}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1} xs={12} sm={6}>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="space-between" spacing={1}>
                        <Typography variant="h4" sx={{ py: 1 }}>
                          Shipping Address
                        </Typography>
                      </Stack>
                    </Grid>
                    {/* {values.isShippingAddressSameAsBillingAddress} -{values.isActive} - */}
                    {/* {values.isShippingAddressSameAsBillingAddress || values.isShippingAddressSameAsBillingAddress === "true" ? ( */}
                    {values.isShippingAddressSameAsBillingAddress ? (
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel>Full Shipping Address</InputLabel>
                          <OutlinedInput
                            fullWidth
                            multiline
                            value={`${values.address}\n${[values.city, values.state, values.country].filter(Boolean).join(", ")} ${values.zip}`}
                            readOnly={true}
                          />
                        </Stack>

                        {/* <Typography variant="body1" sx={{ pt: 1 }}>
                            {values.address} <br />
                            {[values.city, values.state, values.country].filter(Boolean).join(", ")} {values.zip}
                          </Typography> */}
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="shippingAddress">Address</InputLabel>
                            <TextField
                              fullWidth
                              id="shippingAddress"
                              multiline
                              rows={2}
                              placeholder="Address"
                              {...getFieldProps("shippingAddress")}
                              error={Boolean(touched.shippingAddress && errors.shippingAddress)}
                              helperText={touched.shippingAddress && errors.shippingAddress}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="shippingCity">City</InputLabel>
                            <TextField
                              fullWidth
                              id="shippingCity"
                              placeholder="City"
                              {...getFieldProps("shippingCity")}
                              error={Boolean(touched.shippingCity && errors.shippingCity)}
                              helperText={touched.shippingCity && errors.shippingCity}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="shippingState">State</InputLabel>
                            <TextField
                              fullWidth
                              id="shippingState"
                              placeholder="State"
                              {...getFieldProps("shippingState")}
                              error={Boolean(touched.shippingState && errors.shippingState)}
                              helperText={touched.shippingState && errors.shippingState}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="shippingCountry">Country</InputLabel>
                            <TextField
                              fullWidth
                              id="shippingCountry"
                              placeholder="Country"
                              {...getFieldProps("shippingCountry")}
                              error={Boolean(touched.shippingCountry && errors.shippingCountry)}
                              helperText={touched.shippingCountry && errors.shippingCountry}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Stack spacing={1}>
                            <InputLabel htmlFor="shippingZip">Zip Code</InputLabel>
                            <TextField
                              fullWidth
                              id="shippingZip"
                              placeholder="Zip Code"
                              {...getFieldProps("shippingZip")}
                              error={Boolean(touched.shippingZip && errors.shippingZip)}
                              helperText={touched.shippingZip && errors.shippingZip}
                            />
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>{}</Stack>
                  </Grid>
                  {isEditing && (
                    <>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <Typography variant="h4" component="span">
                            Sales Persons
                          </Typography>
                          <Select
                            fullWidth
                            id="salesPersons"
                            multiple
                            placeholder={t("company.salesPersons", "Sales Persons")}
                            {...getFieldProps("salesPersons")}
                            value={values.salesPersons}
                            renderValue={(selected) => (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {salesPersons
                                  .filter((person) => selected.indexOf(person.id ?? "") > -1)
                                  .map((person) => (
                                    <Chip key={person.id} label={person.firstName + " " + person.lastName} />
                                  ))}
                              </Box>
                            )}
                          >
                            {salesPersons.map((person) => (
                              <MenuItem key={person.id} value={person.id}>
                                <Checkbox checked={values.salesPersons.indexOf(person.id ?? "") > -1} />
                                <ListItemText primary={person.firstName + " " + person.lastName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <FieldArray name="locations">
                          {({ push, remove }) => (
                            <>
                              <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1, mb: 1 }}>
                                <Typography variant="h4" component="span">
                                  Locations
                                </Typography>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() =>
                                    push({
                                      // companyId: '',
                                      company: null,
                                      name: "",
                                      shortName: "",
                                      address: "",
                                      address2: "",
                                      phone: "",
                                      city: "",
                                      state: "",
                                      country: "",
                                      zip: "",
                                      isActive: true,
                                    })
                                  }
                                >
                                  <AddCircle />
                                </IconButton>
                              </Stack>
                              <ScrollX>
                                <Stack direction="column" className="locations">
                                  {values?.locations?.map((location, index) => (
                                    <Stack key={index} direction="row" alignItems="center" sx={{ borderTop: index > 0 ? "1px solid #f0f0f0" : null }}>
                                      <IconButton color="secondary" onClick={() => remove(index)} disabled={values?.locations?.length === 1}>
                                        <DeleteOutlined />
                                      </IconButton>
                                      <Grid container rowSpacing={2} columnSpacing={1} direction="row" sx={{ p: 1 }}>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <TextField
                                            label="Location"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.name`}
                                            {...getFieldProps(`locations.${index}.name`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="Short Name"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.shortName`}
                                            {...getFieldProps(`locations.${index}.shortName`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="Phone"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.phone`}
                                            {...getFieldProps(`locations.${index}.phone`)}
                                            helperText={<ErrorMessage name={`locations.${index}.phone`} component="div" className="error" />}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <TextField
                                            label="Address Line"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.address`}
                                            {...getFieldProps(`locations.${index}.address`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={4}>
                                          <TextField
                                            label="Address Line 2"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.address2`}
                                            {...getFieldProps(`locations.${index}.address2`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="City"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.city`}
                                            {...getFieldProps(`locations.${index}.city`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="State"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.state`}
                                            {...getFieldProps(`locations.${index}.state`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="Country"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.country`}
                                            {...getFieldProps(`locations.${index}.country`)}
                                          />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={2}>
                                          <TextField
                                            label="Zip Code"
                                            fullWidth
                                            size="small"
                                            id={`locations.${index}.zip`}
                                            {...getFieldProps(`locations.${index}.zip`)}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  ))}
                                </Stack>
                              </ScrollX>
                            </>
                          )}
                        </FieldArray>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Stack spacing={1} direction="row" justifyContent="flex-end">
                      <Button color="secondary" onClick={() => onCancel()}>
                        {t("general.cancel", "Cancel")}
                      </Button>
                      <Button type="submit" variant="contained" disabled={isSubmitting}>
                        {!isEditing ? "Save & Next" : t("general.save", "Save")}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </>
  );
}
