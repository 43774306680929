import { FormulaComponentObj, FormulaListItem, PrintTypeObj } from "../types/formulation";

export const printType: PrintTypeObj[] = [
  {
    text: "Zebra (4×6) - Portrait",
    width: 4,
    height: 6,
  },
  {
    text: "Zebra (4×6) - Landscape",
    width: 6,
    height: 4,
  },
  // {
  //     text: "Zebra (4×8.25) - Portrait",
  //     width: 4,
  //     height: 8.25
  // },

  // {
  //     text: "Zebra (4×8.25) - Landscape",
  //     width: 8.25,
  //     height: 4
  // },
  {
    text: "Mixing Report",
    width: 0,
    height: 0,
  },
];

export function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

export const sanitize = (filename: string): string => {
  return filename.replace(/[\<\>\:\"\\\/\|\?\*]/g, "_");
};

export const base64ToArrayBuffer = (base64: string): Uint8Array => {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

// TODO: change location, check if it is usefull
export const getFormulaListItems = (formulaComponents: FormulaComponentObj[]) => {
  const formulaListItem: FormulaListItem[] = [];
  if (formulaComponents.length === 0) {
    return formulaListItem;
  }
  formulaComponents.map((formulaCmp: FormulaComponentObj) => {
    let formulaItemObj: FormulaListItem = {
      text: "",
      selected: false,
      value: "0",
    };
    formulaItemObj.text = `${formulaCmp.name}`;
    formulaItemObj.value = `${formulaCmp.id}`;
    formulaListItem.push(formulaItemObj);
  });
  return formulaListItem;
};
