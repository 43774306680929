import { format, FormatOptions } from "date-fns";
import { toZonedTime } from "date-fns-tz";

// TODO: Migrate to dayjs
export function formatDate(
  date: string | number | Date,
  formatStr: string = "MM/dd/yyyy",
  options?: FormatOptions,
  timeZoneOptions?: { fromTimeZone: string; toTimeZone: string }
) {
  try {
    const fromTimeZone = timeZoneOptions?.fromTimeZone || "America/Chicago"; // Default to Central Time
    const toTimeZone = timeZoneOptions?.toTimeZone || "America/Los_Angeles"; // Default to Pacific Time

    // Convert the date to the specified source time zone
    const zonedDate = toZonedTime(new Date(date), fromTimeZone);

    // Transform to the target time zone
    const convertedDate = toZonedTime(zonedDate, toTimeZone);

    return format(convertedDate, formatStr);
  } catch (error) {
    console.error(`Cannot format date '${date}'`);
    console.error(error);
    return "";
  }
}
