import React from "react";
import { Box, Divider, Grid, InputLabel, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import { UnitOfMeasureType, QuoteLineType } from "../../AllTypes";
import useTranslation from "../../hooks/useTranslation";
import useAuth from "hooks/useAuth";
import { formatNo } from "utils/utility";

interface QuoteSummaryProps {
  values: any;
  // location: LocationType | undefined;
  companyName: string;
  isCentral: boolean;
  unitsOfMeasure: UnitOfMeasureType[];
  showPrices: boolean;
}

const QuoteSummary: React.FC<QuoteSummaryProps> = ({ values, companyName, isCentral, unitsOfMeasure, showPrices }) => {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();

  return (
    <>
      <Grid item sm={4}>
        <Stack spacing={1}>
          <InputLabel>{t("quote.customer", "Customer")}:</InputLabel>
          {values?.companyName || companyName}
        </Stack>
      </Grid>
      <Grid item sm={4}>
        <Stack spacing={1}>
          <InputLabel>{t("quote.preparedFor", "Prepared For")}:</InputLabel>
          {values?.preparedFor}
        </Stack>
      </Grid>
      <Grid item sm={4}>
        <Stack direction="column" spacing={1}>
          <InputLabel>{t("quote.salesRepresentative", "Sales Representative")}:</InputLabel>
          {values.id ? values?.salesRepresentativeFullName : `${currentUser?.firstName} ${currentUser?.lastName}`}
        </Stack>
      </Grid>
      <Grid item sm={12}>
        <FieldArray name="lines">
          {() => (
            <>
              <Box
                sx={{
                  overflowX: "auto",
                  border: "1px solid #eee",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        {/* <InputLabel>{t("quote.componentProduct", "Component / Product")}</InputLabel> */}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Stack spacing={1}>
                          <InputLabel>{t("quote.code", "Code")}</InputLabel>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Stack spacing={1}>
                          <InputLabel>{t("quote.description", "Description")}</InputLabel>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>
                        <Stack spacing={1}>
                          {/* <InputLabel>{t("quote.minOrderQuantity", "Min Order Quantity")}</InputLabel> */}
                          <InputLabel>
                            Min Order <br /> Quantity
                          </InputLabel>
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>
                        <Stack spacing={1}>
                          <InputLabel>{t("quote.unit", "Unit")}</InputLabel>
                        </Stack>
                      </TableCell>
                      {showPrices && (
                        <>
                          <TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>
                            <Stack spacing={1}>
                              <InputLabel>{t("quote.price", "Price")}</InputLabel>
                            </Stack>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: "top", textAlign: "center" }}>
                            <Stack spacing={1}>
                              <InputLabel>{t("quote.total", "Total")}</InputLabel>
                            </Stack>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.lines.map((line: QuoteLineType, index: number) => {
                      // let componentType = line.productId
                      //   ? t("formulation.Product", "Product")
                      //   : line.componentId
                      //   ? t("formulation.Component", "Component")
                      //   : "N/A";
                      let unitName = unitsOfMeasure.find((u) => u.id === line.unitOfMeasureId)?.name;
                      return (
                        <TableRow key={index}>
                          {/* <TableCell sx={{ verticalAlign: "top", p: 1 }}>{componentType}</TableCell> */}
                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>{index + 1}.</TableCell>
                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                            <Stack spacing={1}>{line.code}</Stack>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: "top", p: 1 }}>
                            <Stack spacing={1}>{line.description}</Stack>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: "top", p: 1, textAlign: "center" }}>
                            <Stack spacing={0}>{line.minOrderQuantity}</Stack>
                          </TableCell>
                          <TableCell sx={{ verticalAlign: "top", p: 1, textAlign: "center" }}>
                            <Stack spacing={0}>{unitName}</Stack>
                          </TableCell>
                          {showPrices && (
                            <>
                              <TableCell sx={{ verticalAlign: "top", p: 1, textAlign: "center" }}>
                                <Stack spacing={0}>${formatNo(line.unitaryPrice, 2, 2)}</Stack>
                              </TableCell>
                              <TableCell sx={{ verticalAlign: "top", p: 1, textAlign: "center" }}>
                                <Stack spacing={0}>${formatNo(line.totalPrice, 2, 2)}</Stack>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </>
          )}
        </FieldArray>
      </Grid>
      <Grid item sm={12}>
        <Divider />
      </Grid>
    </>
  );
};

export default QuoteSummary;
