import useAuth from "../hooks/useAuth";

export enum RoleType {
  COMPANY_USER = "CompanyUser",
  TEAM_ADMIN = "TeamAdmin",
  COMPANY_ADMIN = "CompanyAdmin",
  ADMIN = "Admin",
  SUPER_ADMIN = "SuperAdmin",
  LOCATION_MACHINE = "LocationMachine",
  CUSTOMER_SERVICE_AGENT = "CustomerServiceAgent",
  SALES_PERSON = "SalesPerson",
}

export const userLevelIds = {
  CompanyUser: 1,
  TeamAdmin: 2,
  CompanyAdmin: 3,
  Admin: 4,
  SuperAdmin: 5,
  Dispenser: 6,
  CustomerServiceAgent: 7,
  SalesPerson: 8,
};

export const userLevels = [
  { value: 1, text: "Company User" },
  { value: 2, text: "Team Admin" },
  { value: 3, text: "Company Admin" },
  { value: 6, text: "Dispenser" },
  { value: 7, text: "Customer Service Agent" },
  { value: 8, text: "Sales Person" },
  { value: 4, text: "Admin" },
];

export const useUserPermissions = () => {
  const { user: currentUser } = useAuth();

  function hasMinLevel(userLevelName: string): boolean {
    if (!currentUser) {
      return false;
    }
    let orderedLevels = ["User", "TeamAdmin", "CompanyAdmin", "CustomerServiceAgent", "SalesPerson", "Admin", "SuperAdmin"];
    let ixLevel = orderedLevels.indexOf(currentUser.userLevelName || "");
    let ixTestLevel = orderedLevels.indexOf(userLevelName);

    return ixLevel !== -1 && ixTestLevel !== -1 && ixLevel >= ixTestLevel;
  }

  function isAdmin() {
    return currentUser?.userLevelName === RoleType.ADMIN || currentUser?.userLevelName === RoleType.SUPER_ADMIN;
  }

  function isCoreUser() {
    return (
      currentUser?.userLevelName === RoleType.SUPER_ADMIN ||
      currentUser?.userLevelName === RoleType.ADMIN ||
      currentUser?.userLevelName === RoleType.CUSTOMER_SERVICE_AGENT ||
      currentUser?.userLevelName === RoleType.SALES_PERSON
    );
  }

  return {
    isAdmin,
    hasMinLevel,
    isCoreUser,
    canModifyPOPrices: () => isAdmin() || currentUser?.userLevelName === RoleType.CUSTOMER_SERVICE_AGENT,
    canModifyQuotePrices: () => isAdmin(),
    canCreatePurchaseOrders: () => isCoreUser() || currentUser?.canCreatePurchaseOrders || false,
    canApprovePurchaseOrders: () => isCoreUser() || currentUser?.isPurchasingAgent || false,
    canCancelPurchaseOrders: () => isCoreUser() || currentUser?.isPurchasingAgent || false,
    canConfirmPurchaseOrders: () => isCoreUser(),
  };
};

// TODO: refactor?
export const getRoleAccessMenu = (roleType: string) => {
  const roleAccesMenuObj = {
    formulation: {
      access: true,
      recycle: {
        access: true,
      },
      dispenser: {
        access: false,
      },
      log: {
        access: true,
      },
    },
    purchaseOrders: {
      access: true,
    },
    inventory: {
      access: true,
      stockLevels: { access: true },
      stockEntries: { access: true },
      componentCosts: { access: false },
      inventoryTransactions: { access: true },
    },
    inks: {
      access: false,
      series: {
        access: false,
      },
      component: {
        access: false,
      },
    },
    inkcal: {
      access: true,
    },
    // "ink-usage-calculator2": {
    //     access: true
    // },
    quoteCentral: {
      access: false,
    },
    admin: {
      access: false,
      company: {
        access: false,
        edit: {
          access: false,
        },
      },
      location: {
        access: false,
      },
      user: {
        access: false,
      },
      userActions: {
        access: false,
      },
      purchaseOrderCentral: {
        access: false,
      },
      product: {
        access: false,
      },
      categories: {
        access: false,
      },
      // categories: {
      //   access: false,
      productCategory: {
        access: false,
      },
      componentCategory: {
        access: false,
      },
      productSubcategory: {
        access: false,
      },
      componentSubcategory: {
        access: false,
      },
      // },
      unitOfMeasure: {
        access: false,
      },
    },
  };
  console.log("roleType", roleType);

  if (roleType === RoleType.LOCATION_MACHINE) {
    roleAccesMenuObj.formulation.access = true;
    roleAccesMenuObj.formulation.dispenser.access = true;
  }
  if (roleType === RoleType.COMPANY_USER) {
  }
  if (roleType === RoleType.TEAM_ADMIN) {
    roleAccesMenuObj.admin.access = true;
    roleAccesMenuObj.admin.user.access = true;
  }
  if (roleType === RoleType.COMPANY_ADMIN) {
    roleAccesMenuObj.formulation.dispenser.access = true;
    roleAccesMenuObj.inventory.componentCosts.access = true;
    roleAccesMenuObj.admin.access = true;
    roleAccesMenuObj.admin.location.access = true;
    roleAccesMenuObj.admin.user.access = true;
  }
  if (roleType === RoleType.CUSTOMER_SERVICE_AGENT) {
    roleAccesMenuObj.formulation.access = false;
    roleAccesMenuObj.formulation.recycle.access = false;
    roleAccesMenuObj.inkcal.access = false;
    roleAccesMenuObj.inventory.access = false;

    roleAccesMenuObj.purchaseOrders.access = true;
    roleAccesMenuObj.admin.access = true;
    roleAccesMenuObj.admin.purchaseOrderCentral.access = true;
    roleAccesMenuObj.admin.company.access = true;
    roleAccesMenuObj.admin.company.edit.access = true;
    roleAccesMenuObj.admin.location.access = true;
    roleAccesMenuObj.admin.user.access = true;
    roleAccesMenuObj.admin.userActions.access = true;
  }
  if (roleType === RoleType.SALES_PERSON) {
    roleAccesMenuObj.formulation.access = false;
    roleAccesMenuObj.formulation.recycle.access = false;
    roleAccesMenuObj.inkcal.access = false;
    roleAccesMenuObj.inventory.access = false;

    roleAccesMenuObj.purchaseOrders.access = true;
    roleAccesMenuObj.quoteCentral.access = true;
    roleAccesMenuObj.admin.access = true;
    roleAccesMenuObj.admin.purchaseOrderCentral.access = true;
    roleAccesMenuObj.admin.company.access = true;
    roleAccesMenuObj.admin.company.edit.access = true;
    roleAccesMenuObj.admin.location.access = true;
    roleAccesMenuObj.admin.user.access = true;
    roleAccesMenuObj.admin.userActions.access = true;
  }
  if (roleType === RoleType.ADMIN || roleType === RoleType.SUPER_ADMIN) {
    roleAccesMenuObj.formulation.dispenser.access = true;
    roleAccesMenuObj.inventory.componentCosts.access = true;
    roleAccesMenuObj.inks.access = true;
    roleAccesMenuObj.inks.series.access = true;
    roleAccesMenuObj.inks.component.access = true;
    roleAccesMenuObj.quoteCentral.access = true;
    roleAccesMenuObj.admin.access = true;
    roleAccesMenuObj.admin.purchaseOrderCentral.access = true;
    roleAccesMenuObj.admin.company.access = true;
    roleAccesMenuObj.admin.company.edit.access = true;
    roleAccesMenuObj.admin.location.access = true;
    roleAccesMenuObj.admin.user.access = true;
    roleAccesMenuObj.admin.userActions.access = true;
    roleAccesMenuObj.admin.product.access = true;
    roleAccesMenuObj.admin.categories.access = true;
    roleAccesMenuObj.admin.productCategory.access = true;
    roleAccesMenuObj.admin.componentCategory.access = true;
    roleAccesMenuObj.admin.productSubcategory.access = true;
    roleAccesMenuObj.admin.componentSubcategory.access = true;
    roleAccesMenuObj.admin.unitOfMeasure.access = true;
  }

  return roleAccesMenuObj;
};
