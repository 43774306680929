import { Box } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "../sidebar/SideBar";
import Home from "../home";
import Company from "../company";
import CompanyEdit from "../company/CompanyEdit";
import FormulaRecycle from "../formulation/FormulaRecycle";
import Inventory from "../inventory";
import Series from "../series";
import Location from "../location";
import User from "../user";
import UserActions from "../user-actions/UserActions";
import NotFound from "../not-found";
import FormulaCreationLog from "../formulation/FormulaCreationLog";
import InkUsageCalculator2 from "../ink-usage-calculator/InkUsageCalculator2";
import Formulation from "../formulation/Formulation";
import FormulationDispenser from "../formulation/DispenserFormulation";
import Ink from "../component/ComponentList";
import Login from "../authentication/login";
import ForgotPasword from "../authentication/forgot-password";
import CheckMailMessage from "../authentication/check-mail-message";
import ResetPassword from "../authentication/reset-password";
import ThankyouMessage from "../authentication/thankyou-message";
import UnauthorizedMessage from "../authentication/unauthorized-message";
import PurchaseOrderCentral from "../purchaseOrders/PurchaseOrderCentral";
import PurchaseOrders from "../purchaseOrders/PurchaseOrders";
import QuoteCentral from "../quotes/QuoteCentral";
import ProductList from "../product/ProductList";
import ProductCategoryList from "../productCategory/ProductCategoryList";
import ProductSubcategoryList from "../productSubcategory/ProductSubcategoryList";
import ComponentCategoryList from "../componentCategory/ComponentCategoryList";
import ComponentSubcategoryList from "../componentSubcategory/ComponentSubcategoryList";
import UnitOfMeasureList from "../unitOfMeasure/UnitOfMeasureList";
import QuoteDetail from "pages/quotes/QuoteDetail";
import ViewQuote from "pages/quotes/ViewQuote";
import SendQuote from "pages/quotes/SendQuote";

const HomeLayout = () => {
  return (
    <Box>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPasword />} />
          <Route path="/check-mail-message" element={<CheckMailMessage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/thank-you-completion" element={<ThankyouMessage />} />
          <Route path="/unauthorized-access" element={<UnauthorizedMessage />} />
          <Route
            path="/*"
            element={
              <Box display="flex">
                <Sidebar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/formulation" element={<Formulation />} />
                  <Route path="/formulation/dispenser" element={<FormulationDispenser />} />
                  <Route path="/formulation/recycle" element={<FormulaRecycle />} />
                  <Route path="/formulation/log" element={<FormulaCreationLog />} />
                  <Route path="/formulation/recycle?formulaId=:formulaId" element={<FormulaRecycle />} />
                  <Route path="/inventory/*" element={<Inventory />} />
                  <Route path="/purchaseOrders/*" element={<PurchaseOrders />} />
                  <Route path="/404" element={<NotFound />} />
                  <Route path="/inks/component" element={<Ink />} />
                  <Route path="/inks/series" element={<Series />} />
                  {/* <Route path="ink-usage-calculator" element={<InkUsageCalculator />} /> */}
                  <Route path="/ink-usage-calculator" element={<InkUsageCalculator2 />} />
                  <Route path="/admin/company" element={<Company />} />
                  <Route path="/admin/company/:companyId" element={<CompanyEdit />} />
                  <Route path="/admin/location" element={<Location />} />
                  <Route path="/admin/user" element={<User />} />
                  <Route path="/admin/purchaseOrderCentral/*" element={<PurchaseOrderCentral />} />
                  <Route path="/quoteCentral" element={<QuoteCentral />} />
                  <Route path="/quoteCentral/:quoteId/edit" element={<QuoteDetail isCentral={true} />} />
                  <Route path="/quoteCentral/:quoteId/view" element={<ViewQuote isCentral={true} />} />
                  <Route path="/quoteCentral/:quoteId/send" element={<SendQuote isCentral={true} />} />
                  <Route path="/admin/product" element={<ProductList />} />
                  <Route path="/admin/productCategory" element={<ProductCategoryList />} />
                  <Route path="/admin/productSubcategory" element={<ProductSubcategoryList />} />
                  <Route path="/admin/componentCategory" element={<ComponentCategoryList />} />
                  <Route path="/admin/componentSubcategory" element={<ComponentSubcategoryList />} />
                  <Route path="/admin/unitOfMeasure" element={<UnitOfMeasureList />} />
                  <Route path="/admin/userActions" element={<UserActions />} />
                  {/* Catch-all route for not found */}
                  {/* <Route path="*" element={<NotFound />} /> */}
                </Routes>
              </Box>
            }
          />
        </Routes>
      </BrowserRouter>
    </Box>
  );
};

export default HomeLayout;
