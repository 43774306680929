import React, { MouseEvent, useEffect } from "react";

// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// project-import
import IconButton from "../../components/@extended/IconButton";

import { Backdrop, Fade, Modal, Stack, Typography, RadioGroup, FormControlLabel, Radio, Switch, OutlinedInput } from "@mui/material";
import { useMemo, useState } from "react";

import { CompanyType } from "../../AllTypes";
import DisplayTable from "../../components/DisplayTable";
import AddCompanyModal from "./company-helpers/AddCompanyModal";
import MainLayout from "../main-layout";
import axiosServices from "../../utils/axios";
import { CompanyEndpoints } from "../../AllLables";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { Chip } from "@mui/material";
import AlertItemDelete from "../pages-helpers/AlertItemDelete";
import { alertMessage } from "../pages-helpers/AlertMessage";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider, FieldArray } from "formik";
import { AddCircle } from "@mui/icons-material";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { insert, updateUsingPut } from "../../api/generalRoute";
import useTranslation from "../../hooks/useTranslation";
import CompanyEditForm from "./CompanyEditForm";

type Params = {
  companyId: string;
};

export default function CompanyEdit() {
  const { companyId } = useParams<Params>();
  const navigate = useNavigate();

  return (
    <MainLayout>
      <CompanyEditForm
        companyId={companyId}
        onCancel={() => navigate("/admin/company")}
        onSave={(id) => (!companyId ? navigate(`/admin/company/${id}`) : navigate("/admin/company"))}
      />
    </MainLayout>
  );
}
