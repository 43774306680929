import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Tooltip, Typography, IconButton } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { CloseOutlined } from "@ant-design/icons";

import axiosServices from "../../utils/axios";
import { alertMessage } from "../pages-helpers/AlertMessage";
import { LocationType, QuoteLineType, UnitOfMeasureType, ProductType, ComponentType, CompanyType, SelectCompanyType } from "../../AllTypes";
import HighlightedText from "../../components/HighlightedText";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { useUserPermissions } from "../../utils/userPermissions";
import { AddOutlined, Check, EmailOutlined, Redo, Save, Send, Warning } from "@mui/icons-material";
import { formatDate } from "../../utils/formatDate";
import QuoteSummary from "./QuoteSummary";
import useTranslation from "../../hooks/useTranslation";
import { totalmem } from "os";
import useAuth from "hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "pages/main-layout";

interface Props {
  companyId?: number | null;
  isCentral: boolean;
}

type Params = {
  quoteId?: string;
};

interface ProductComponentOption {
  value: string;
  text: string;
  description: string;
  defaultUnitOfMeasureId?: string;
  type: "component" | "product";
  unitOfMeasureIds?: [number];
}

const ItemSchema = Yup.object().shape({
  quantity: Yup.number().required("Quantity is required").positive("Quantity must be a positive number."),
  minOrderQuantity: Yup.number().required("Min Order Quantity is required").positive("Min Order Quantity must be a positive number."),
  unitOfMeasureId: Yup.string().required("Unit of Measure is required"),
});

function formatNo(pct: number, maxDigits?: number, minDigits?: number) {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  });
  return formatter.format(pct);
}

const ViewQuote: React.FC<Props> = ({ companyId: defaultCompanyId, isCentral }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<UnitOfMeasureType[]>([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>("");
  const [submitAction, setSubmitAction] = useState<"" | "saveAsQuoteRequest" | "saveAsQuote">("");
  const [action, setAction] = useState<"view" | "edit">("view");
  const [mode, setMode] = useState<"confirmation" | "edition" | "viewOnly" | null>(null);
  const [title, setTitle] = useState("Add Quote");
  const { quoteId: queryQuoteId } = useParams<Params>();
  const quoteId = useMemo(() => (queryQuoteId && queryQuoteId !== "0" ? parseInt(queryQuoteId) : null), [queryQuoteId]);

  const permissions = useUserPermissions();
  const { user: currentUser } = useAuth();

  useEffect(() => {
    setLoading(true);
    axiosServices
      .get("/api/unitOfMeasure")
      .then((res) => {
        setUnitsOfMeasure(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const initialValues = {
    id: 0,
    // billingAddress: "",
    // shippingAddress: "",
    // billingAddressLocationId: 0,
    // shippingAddressLocationId: 0,
    // quoteNumber: "",
    // shipVia: "",
    // collectAccountNumber: "",
    // salesOrderNumber: "",
    // status: 1,
    // statusName: "Draft",
    // statusDisplayName: "Draft",
    // totalPrice: 0,
    preparedFor: "",
    salesRepresentativeId: null,
    salesRepresentativeFullName: "",
    companyId: defaultCompanyId && defaultCompanyId > 0 ? defaultCompanyId : null,
    // notes: "",
    lines: [
      {
        id: 0,
        componentId: null,
        productId: null,
        code: "",
        description: "",
        quantity: 0,
        minOrderQuantity: 0,
        unitaryPrice: 0,
        totalPrice: 0,
        unitOfMeasureId: null,
        unitOfMeasureIds: [],
      },
    ],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      companyId: Yup.number().positive("Customer is required").required("Customer is required"),
      lines: Yup.array().of(ItemSchema),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      console.log({ onSubmit: submitAction });

      // if (!confirmationMode) {
      if (mode === "edition") {
        setMode("confirmation");
        // setConfirmationMode(true);
        setSubmitting(false);
        return;
      }

      let url = "";
      let successfulMessage = "";
      if (submitAction === "saveAsQuoteRequest") {
        url = `/api/quote/request`;
        successfulMessage = t("quote.requestSavedMessage", "Quote request saved successfully");
      } else if (submitAction === "saveAsQuote") {
        url = `/api/quote/quote`;
        successfulMessage = t("quote.savedMessage", "Quote saved successfully");
      } else {
        setSubmitting(false);
        return;
      }

      axiosServices
        .post(url, values)
        .then((res) => {
          alertMessage(successfulMessage, "success");
          closeModal(true);
        })
        .catch((err) => {
          alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
          setMode("edition");
        })
        .finally(() => {
          setSubmitting(false);
          // setConfirmationMode(false);
        });
    },
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps, handleChange, setValues, setFieldValue } = formik;

  useEffect(() => {
    if (!submitAction) return;
    handleSubmit();
  }, [submitAction, handleSubmit]);

  useEffect(() => {
    if (!quoteId) {
      return;
    }

    setLoading(true);
    axiosServices
      .get(`/api/quote/${quoteId}`)
      .then((res) => {
        let quote = res.data;
        quote.lines = quote.lines.map((l: any) => {
          return {
            ...l,
            component: l.componentId
              ? { value: l.componentId.toString(), text: l.componentName, description: l.componentDescription }
              : l.productId
              ? { value: l.productId.toString(), text: l.productCode, description: l.productDescription }
              : { value: 0, text: "", description: "" },
            unitOfMeasureIds: [
              ...new Set(
                l.componentId
                  ? l.componentUnitsOfMeasure?.map((u: any) => u.unitOfMeasureId)
                  : l.productUnitsOfMeasure?.map((u: any) => u.unitOfMeasureId)
              ),
            ],
          };
        });

        setValues(quote);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [quoteId]);

  useEffect(() => {
    if (quoteId && !values?.id) {
      // Quote has not loaded yet
      return;
    }

    setMode("viewOnly");
    setAction("view");
  }, [quoteId, values, isCentral]);

  useEffect(() => {
    if (action === "view") {
      setTitle(t("quote.viewTitle", "View Quote"));
    }
  }, [quoteId, action]);

  function closeModal(modified?: boolean, skipConfirmClose?: boolean) {
    navigate(`/quoteCentral`);
  }

  return (
    <MainLayout>
      <FormikProvider value={formik}>
        <Form noValidate onSubmit={handleSubmit}>
          <Stack direction="column" spacing={2}>
            <Stack sx={{ pr: 1 }} direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h2" component="span">
                {title}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0}>
              <Grid container spacing={2}>
                {(mode === "confirmation" || mode === "viewOnly") && (
                  <QuoteSummary
                    values={values}
                    companyName={selectedCompanyName}
                    isCentral={isCentral}
                    unitsOfMeasure={unitsOfMeasure}
                    showPrices={true}
                  />
                )}
              </Grid>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              {mode === "viewOnly" && (
                <Button color="secondary" onClick={() => closeModal(false)}>
                  Back
                </Button>
              )}
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </MainLayout>
  );
};

export default ViewQuote;
