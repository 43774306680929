// material-ui
import Tooltip from "@mui/material/Tooltip";

// third-party
import { ColumnDef } from "@tanstack/react-table";

// assets
import {
  TextField,
  InputAdornment,
  Box,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { formatDate } from "../../utils/formatDate";

import SearchOutlined from "@ant-design/icons/SearchOutlined";

import React, { Fragment, useEffect, useMemo, useState } from "react";

import DisplayTable from "../../components/DisplayTable";
import axiosServices from "../../utils/axios";
import CircularWithPath from "../../components/@extended/progress/CircularWithPath";
import { alertMessage } from "../pages-helpers/AlertMessage";

import useAuth from "../../hooks/useAuth";
import { SelectCompanyType, QuoteType } from "../../AllTypes";
import MainLayout from "../main-layout";
import { EditOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import { Cancel, CancelOutlined, DescriptionOutlined } from "@mui/icons-material";
import ConfirmAction from "../pages-helpers/ConfirmAction";
import { Divider } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { useNavigate } from "react-router-dom";
import { useUserPermissions } from "utils/userPermissions";

export default function QuoteCentral() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user: currentUser } = useAuth();
  const [refreshData, setRefreshData] = useState(false);
  const [quotes, setQuotes] = useState<QuoteType>();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<SelectCompanyType[]>([]);
  // const [locations, setLocations] = useState<LocationType[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(-1);
  // const [selectedLocationId, setSelectedLocationId] = useState(-1);
  // const [openModal, setOpenModal] = useState(false);
  // const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  // const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  // const [quoteId, setQuoteId] = useState(0);
  const [search, setSearch] = useState("");
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const [confirmCancelId, setConfirmCancelId] = useState<number | null>(null);

  const permissions = useUserPermissions();

  useEffect(() => {
    axiosServices
      .get("/api/company/GetAllCompanies")
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error", err);
      });
  }, []);

  let toggleRefreshData = () => setRefreshData(!refreshData);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    // // Set default company if user cannot modify it
    // if (currentUser && !hasMinLevel(currentUser, "Admin")) {
    //   setSelectedCompanyId(currentUser?.companyId || 0);
    // }

    // // Set default location if user cannot modify it
    // if (!hasMinLevel(currentUser, "CompanyAdmin")) {
    //   setSelectedLocationId(currentUser?.locationId || 0);
    // }
  }, [currentUser]);

  // useEffect(() => {
  //   if (!selectedCompanyId) {
  //     return;
  //   }

  //   if (selectedCompanyId === -1) {
  //     setLocations([]);
  //     setSelectedLocationId(-1);
  //     return;
  //   }

  //   axiosServices
  //     .get(`/api/Location/GetLocationsByCompanyId?CompanyId=${selectedCompanyId}`)
  //     .then((res) => {
  //       let locations = res.data;
  //       setLocations(locations);

  //       // Default select if there is only one location
  //       if (locations?.length === 1) {
  //         setSelectedLocationId(locations[0].id);
  //       }
  //     })
  //     .catch((err) => {
  //       alertMessage("Something went wrong", "error");
  //     });
  // }, [selectedCompanyId]);

  useEffect(() => {
    searchQuotes();
  }, [refreshData]);

  function searchQuotes() {
    setLoading(true);
    axiosServices
      .get(`/api/quote?companyId=${selectedCompanyId || -1}`)
      .then((res) => {
        setQuotes(res.data);
      })
      .catch((err) => {
        alertMessage("Something went wrong", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const columns = useMemo<ColumnDef<QuoteType>[]>(
    () => [
      {
        header: t("general.company", "Company"),
        accessorKey: "companyName",
        dataType: "text",
      },
      {
        header: t("quote.salesRepresentative", "Sales Representative"),
        accessorKey: "salesRepresentativeFullName",
        dataType: "text",
      },
      {
        header: t("quote.status", "Status"),
        accessorKey: "statusDisplayName",
        dataType: "text",
      },
      {
        header: t("quote.dateCreated", "Date Created"),
        accessorKey: "dateCreated",
        dataType: "text",
        meta: {
          className: "cell-center",
        },
        cell: ({ row }) => {
          return formatDate(row.original.dateCreated || "", "PP");
        },
      },
      {
        header: t("general.actions", "Actions"),
        id: "actions",
        disableSortBy: true,
        cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <Tooltip title={t("quote.viewQuote", "View Quote")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    openViewQuoteModal(row.original.id);
                  }}
                >
                  <DescriptionOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("quote.editQuote", "Edit Quote")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    openAddModal(row.original.id);
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("quote.sendQuote", "Send Quote")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    openSendQuoteModal(row.original.id);
                  }}
                >
                  <SendOutlined />
                </IconButton>
              </Tooltip>

              {/* Send test button */}
              {/* <Tooltip title={t("quote.sendTest", "Send Test")}>
                <IconButton
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    const quoteId = row.original.id;

                    // hit GET api/quote/{id}/emailTemplate/sent and open tab with the result
                    axiosServices
                      .get(`/api/quote/${quoteId}/emailTemplate/sent`)
                      .then((res) => {
                        console.log({ res });
                        const html = res.data;
                        // open a new tab with the html
                        const newWindow = window.open("", "_blank");
                        newWindow?.document.write(html);
                      })
                      .catch((err) => {
                        alertMessage("Something went wrong", "error", err);
                      });
                  }}
                >
                  <SendOutlined />
                </IconButton>
              </Tooltip> */}

              <Tooltip title={t("quote.cancelQuote", "Cancel Quote")}>
                <IconButton
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmCancelId(row.original.id);
                    setShowConfirmAction(true);
                  }}
                >
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
        meta: {
          className: "cell-center",
        },
      },
    ],
    []
  );

  function openAddModal(quoteId: number) {
    navigate(`/quoteCentral/${quoteId}/edit`);
    // setQuoteId(quoteId);
    // setOpenModal(true);
  }

  function openViewQuoteModal(quoteId: number) {
    navigate(`/quoteCentral/${quoteId}/view`);
    // setQuoteId(quoteId);
    // setIsViewModalOpen(true);
  }

  function openSendQuoteModal(quoteId: number) {
    navigate(`/quoteCentral/${quoteId}/send`);
    // setQuoteId(quoteId);
    // setIsSendModalOpen(true);
  }

  function CompanyInput() {
    return (
      <>
        <InputLabel htmlFor="companyId">Company</InputLabel>
        <Select
          id="companyId"
          fullWidth
          input={<OutlinedInput />}
          value={selectedCompanyId}
          onChange={(e) => {
            if (e.target.value === "-1") {
              navigate(`/quoteCentral/${0}/edit?newCompany=true`);
            } else {
              setSelectedCompanyId(+e.target.value);
            }
          }}
        >
          {!companies && (
            <Box sx={{ p: 5 }}>
              <Stack direction="row" justifyContent="center">
                <CircularWithPath />
              </Stack>
            </Box>
          )}
          <MenuItem key="-1" value="-1">
            All companies
          </MenuItem>
          {companies &&
            [
              <MenuItem key="-1" value="-1">
                <i>{t("general.addNewCompany", "Add New Company")}</i>
              </MenuItem>,
            ].concat(
              companies?.map((item, index) => (
                <MenuItem key={index} value={parseInt(item.value)}>
                  {item.text}
                </MenuItem>
              ))
            )}
        </Select>
      </>
    );
  }

  // function LocationInput() {
  //   return hasMinLevel(currentUser, "CompanyAdmin") ? (
  //     <>
  //       <InputLabel htmlFor="locationId">Location</InputLabel>
  //       <Select
  //         id="locationId"
  //         fullWidth
  //         input={<OutlinedInput />}
  //         value={selectedLocationId}
  //         onChange={(e) => setSelectedLocationId(+e.target.value)}
  //       >
  //         {!locations && (
  //           <Box sx={{ p: 5 }}>
  //             <Stack direction="row" justifyContent="center">
  //               <CircularWithPath />
  //             </Stack>
  //           </Box>
  //         )}
  //         <MenuItem key="-1" value="-1">
  //           All locations
  //         </MenuItem>
  //         {locations &&
  //           locations?.map((item, index) => (
  //             <MenuItem key={index} value={item.id}>
  //               {item.name}
  //             </MenuItem>
  //           ))}
  //       </Select>
  //     </>
  //   ) : (
  //     <>
  //       <InputLabel htmlFor="locationLabel">Location</InputLabel>
  //       <OutlinedInput fullWidth id="locationLabel" value={currentUser?.locationName} name="locationLabel" readOnly={true} />
  //     </>
  //   );
  // }

  function confirmCloseModal() {
    setShowConfirmCloseModal(true);
  }
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);

  return (
    <MainLayout>
      <Box sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Stack direction="row">
            <Box>
              <Typography variant="h2">{t("quote.centralTitle", "Quotes Central")}</Typography>
            </Box>
          </Stack>
        </Grid>
      </Box>
      <Grid container spacing={1}>
        {/* <Grid item sm={6} xs={12}>
          <CompanyInput></CompanyInput>
        </Grid> */}
        {/* <Grid item sm={6} xs={12}>
          <LocationInput></LocationInput>
        </Grid> */}
        <Grid item container sm={12} spacing={1}>
          <Grid item xs>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between" spacing={1}>
              <Stack direction="column" spacing={1} sx={{ width: "100%" }}>
                <CompanyInput></CompanyInput>
              </Stack>
              <Box sx={{ pb: 0.5 }}>
                <Button onClick={searchQuotes} variant="contained">
                  Search
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined></SearchOutlined>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Grid>
        <Grid item sm={12}>
          <Stack direction="row" spacing={1}>
            {permissions.isCoreUser() && (
              <Button
                onClick={() => {
                  navigate(`/quoteCentral/${0}/edit?newCompany=true`);
                }}
                variant="contained"
                startIcon={<PlusOutlined />}
                size="small"
              >
                New Company
              </Button>
            )}

            <Button
              color="primary"
              size="small"
              variant="contained"
              disabled={!selectedCompanyId}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/quoteCentral/0/edit?companyId=${selectedCompanyId}`);
                // setOpenModal(true);
                // setQuoteId(0);
              }}
              startIcon={<PlusOutlined />}
            >
              Add Quote
            </Button>
          </Stack>
        </Grid>
        {quotes && (
          <Grid item sm={12}>
            <DisplayTable defaultSorting={[{ id: "dateCreated", desc: true }]} columns={columns} data={quotes} search={search} showAddBtn={true} />
          </Grid>
        )}
      </Grid>
      {loading && !quotes && (
        <Box sx={{ p: 5 }}>
          <Stack direction="row" justifyContent="center">
            <CircularWithPath />
          </Stack>
        </Box>
      )}
      {!loading && !quotes && (
        <Box sx={{ p: 5, textAlign: "center", fontStyle: "italic" }}>
          <Typography color="gray">Select a company to view quotes.</Typography>
        </Box>
      )}
      {/* <AtlasModal
        open={openModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            confirmCloseModal();
          }
        }}
      >
        <AddQuoteModal
          closeModal={(modified?: boolean, skipConfirmClose?: boolean) => {
            if (modified) {
              toggleRefreshData();
              setOpenModal(false);
            } else if (skipConfirmClose) {
              setOpenModal(false);
            } else {
              confirmCloseModal();
            }
          }}
          quoteId={quoteId}
          companyId={quoteId ? null : selectedCompanyId}
          isCentral={true}
        />
      </AtlasModal> */}
      {/* <AtlasModal open={isViewModalOpen} onClose={() => setIsViewModalOpen(false)}>
        <ViewQuoteModal closeModal={() => setIsViewModalOpen(false)} quoteId={quoteId} isCentral={true} />
      </AtlasModal>
      <AtlasModal open={isSendModalOpen} onClose={() => setIsSendModalOpen(false)}>
        <SendQuoteModal closeModal={() => setIsSendModalOpen(false)} quoteId={quoteId} isCentral={true} />
      </AtlasModal> */}

      <ConfirmAction
        title={t("quote.cancelQuote", "Cancel Quote")}
        confirmMessage={t("quote.confirmCancelMessage", "Are you sure you want to cancel this quote?")}
        open={showConfirmAction}
        onConfirm={() => {
          axiosServices
            .post(`/api/quote/${confirmCancelId}/cancel`)
            .then(() => {
              alertMessage(t("quote.cancelSuccess", "Quote cancelled successfully."), "success");
              setShowConfirmAction(false);
              toggleRefreshData();
            })
            .catch((err) => {
              alertMessage(t("general.somethingWentWrong", "Something went wrong"), "error", err);
            });
        }}
        onClose={() => setShowConfirmAction(false)}
        icon={<Cancel></Cancel>}
        color="error"
      />
      {/* <ConfirmAction
        title={t("general.confirmClose", "Confirm Close")}
        confirmMessage={t("quote.confirmClose", "Are you sure you want discard changes and close?")}
        open={showConfirmCloseModal}
        onConfirm={() => {
          setOpenModal(false);
          setShowConfirmCloseModal(false);
        }}
        onClose={() => setShowConfirmCloseModal(false)}
        icon={<Warning></Warning>}
        color="warning"
        confirmButtonText={t("quote.discardChanges", "Discard Changes")}
      /> */}
    </MainLayout>
  );
}
